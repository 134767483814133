import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/duplicato-smarrimento/dossier.json';
import ContentDossier from '../../components/ContentDossiers';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';


const DuplicatoSmarrimento = () => {
  return (
    <Layout>
      <Seo
        title={"Duplicato della Patente | Autoscuola Faro, Pisa"}
        description={"Ottieni il duplicato della tua patente a seguito di furto, smarrimento o distruzione della patente stessa. Ci pensiamo noi!"}
        keywords={[
          "Duplicato Patente Pisa",
          "Duplicato Patente Furto Smarrimento Pisa",
          "Richiesta Duplicato Patente Auto Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patente" />
      <ContentDossierPrice id="smarrimento" dossier={dossier} />
    </Layout>
  );
};

export default DuplicatoSmarrimento;
